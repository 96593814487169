<template>
  <div class="admin-account">
    <div class="admin-box" v-if="isshow">
      <p>企业账号登录</p>
      <div class="admin-box-login">
        <span>企业账号：</span>
        <el-input type="text" placeholder="18位统一社会信用代码" v-model="text" maxlength="18" show-word-limit>
        </el-input>
      </div>
      <div class="admin-box-login">
        <span>密码：</span>
        <div class="admin-box-login-div">
          <el-input :type="mimas" placeholder="请输入您的密码" v-model="passwordS" maxlength="50">
            <i @click="eye" v-show="suo" slot="suffix" class="iconfont  i-icon-eye-open" style="font-size:24px;right:10px"></i>
            <i @click="eye" v-show="!suo" slot="suffix" class="iconfont  i-biyan" style="font-size:24px;right:10px"></i>
            <!-- <i @click="eye" v-show="suo"  slot="suffix" name="biyan" style="font-size:30px;"></i> -->
            <!-- <c-icon @click.native="eye" v-show="!suo"   slot="suffix" name="biyan" style="font-size:30px;fill:#666;margin-right:5px;"></c-icon>
            <c-icon @click.native="eye" v-show="suo"  slot="suffix" name="icon-eye-open" style="font-size:30px;fill:#666;"></c-icon> -->
          </el-input>

        </div>
      </div>
      <div class="admin-button">
        <div class="admin-button-box">
          <a @click="register()">登 录</a>
          <span @click="handleforget()">忘记密码</span>
        </div>
      </div>
    </div>
    <div class="admin-box" v-else>
      <p>修改密码</p>
      <div class="admin-box-login">
        <span>新密码：</span>
        <div class="admin-box-login-div">
          <input :type="editpasssure" placeholder="请输入您的密码" v-model="editpasswordSx">
          <i @click="editeyeS" class="gai"><img :src="editsuoS?require('../../../assets/img/bi.png'):require('../../../assets/img/zheng.png')" alt=""></i>
        </div>
      </div>
      <div class="admin-box-login">
        <span>再次确认：</span>
        <div class="admin-box-login-div">
          <input :type="editpass" placeholder="请输入您的密码" v-model="editpasswordS">
          <i @click="editeyeX" class="gai"><img :src="editsuo?require('../../../assets/img/bi.png'):require('../../../assets/img/zheng.png')" alt=""></i>
        </div>
      </div>
      <div class="admin-button">
        <div class="admin-button-box">
          <a @click="editregister()">确 定</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  first_login_alter_ent_acct_pwd
} from "../../../api/login"
import {
  ent_acct_login
} from '../../../api/foodqiye'
import {
  toggle_user_ent
} from '../../../api/pulic'
export default {
  data () {
    return {
      testnum: 0,
      text: '',
      mimas: 'password',
      password: '',
      passwordS: '',
      suo: false,
      isshow: true,
      editpass: 'password',
      editpasssure: 'password',
      editpassword: '',
      editpasswordS: '',
      editpasswordSx: '',
      editsuo: true,
      editsuoS: true,
      mm: {},
    }
  },
  methods: {
    handleChange (val) {

    },
    eye () {
      this.suo = !this.suo
      if (this.suo) {
        this.mimas = 'text'
      } else {
        this.mimas = 'password'
      }
    },
    // 确认登录
    register () {
      let self = this
      if (this.text === '' || this.passwordS === '') {
        console.log(1)
        this.$message({
          message: '不能为空',
          type: 'error'
        })
      } else {
        if (this.text.length != 18) {
          this.$message({
            message: '企业账户名错误',
            type: 'warning'
          })
        } else {
          ent_acct_login({
            data: {
              ent_acct: this.text,
              ent_pwd: this.passwordS
            }
          }).then((res) => {

            if (res.code == 10017) {
              this.$store.state.ent_info = res.body.data.ent_info;
              self.mm = res.body.data.ent_info
              // sessionStorage.setItem('ent_info', res.body.data.ent_info)
              console.log('登录成功后返回的信息', res)
              // 存储信息到本地
              this.$message({
                message: '登录成功',
                type: 'success'
              })
              this.$router.push({
                name: 'Admin',
                params: {
                  ent_info: self.mm
                }
              })
              this.passwordS = ''
              sessionStorage.setItem('isLogin', 'true')
              sessionStorage.setItem('ent_info', self.mm.ent_id)
              console.log(this.$store.state.ent_id,'之前存储的企业id');


              // toggle_user_ent({
              //   data: {
              //     user_id: this.$store.state.user_id,
              //     ent_id: res.body.data.ent_info.ent_id
              //   }
              // }).then(res => {
              //   console.log(res, 'sssssssssssssssss');

              //   console.log(this.$store.state.qyInfo, this.$store.state.user_id, 'ss');
              // })

            } else if (res.code == 10088) {
              this.$message({
                message: '登录成功,进入修改密码页',
                type: 'success'
              })
              this.isshow = false
              this.passwordS = ''
            }
            else if (res.code == 700) {
              this.$message({
                message: '账号不存在',
                type: 'success'
              })
            }
            else if (res.code == 10117) {
              this.$message({
                message: '您的企业已解散,请重新注册',
                type: 'success'
              })
            }
            else if (res.code == 10036) {
              this.$message({
                message: '密码错误',
                type: 'error'
              })
            }
          })
        }
      }
    },

    //忘记密码
    handleforget () {
      this.$router.push('/AdminForget')
    },
    // 密码显示隐藏
    editeyeX () {
      this.editsuo = !this.editsuo
      if (this.editsuo) {
        this.editpass = 'password'
      } else {
        this.editpass = 'text'
      }
    },
    editeyeS () {
      this.editsuoS = !this.editsuoS
      if (this.editsuoS) {
        this.editpasssure = 'password'
      } else {
        this.editpasssure = 'text'
      }
    },
    // 确定修改按钮
    editregister () {
      let self = this
      if (this.editpasswordSx !== '' && this.editpasswordS !== '') {
        if (this.editpasswordSx !== this.editpasswordS) {
          this.$message({
            message: '密码不一致',
            type: 'error'
          })
        } else {
          console.log(this.text, this.editpasswordSx);
          const pass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
          if (!pass.test(this.editpasswordSx)) {
            this.$message({
              message: '请输入6-20位数字与字母组合密码',
              type: 'error'
            })
          } else {

            first_login_alter_ent_acct_pwd({
              data: {
                ent_acct: this.text,
                ent_pwd: this.editpasswordSx
              }
            }).then(res => {
              console.log(res, 'xiugai');
              if (res.code == 10021) {

                this.$message({
                  message: '密码修改成功，请重新登录企业账号',
                  type: 'success'
                })

                // this.$router.push('/Admin')
                // this.$router.push('/AdminAccount')
                this.isshow = true

              } else {
                this.$message({
                  message: '修改失败',
                  type: 'error'
                })
              }
            })

          }

        }
      } else {
        this.$message({
          message: '新旧密码不能为空',
          type: 'error'
        })
      }
    }
  },

  created () {
    if (this.$route.params.type == 'zhu') {
      location.reload()
    }
    let a = sessionStorage.getItem('isLogin')
    //
    if (a == 'true') {
      //
      this.$router.push({
        name: 'Admin',
        params: {
          ent_info: sessionStorage.getItem('ent_info'),
          isShow: true
        }
      })
      // this.$router.push('/Admin')
    } else {
      //
    }
  },

}

</script>

<style scoed lang="scss">
.el-main {
  //
  background: #f6f6fa;
}
.admin-account .admin-box .admin-box-login i {
  top: 12px;
}
.admin-account {
  width: 100%;
  background: #fff;
  padding: 84px 0 220px 0;
  -moz-box-shadow: 0px 0px 10px #e8e8eb;
  -webkit-box-shadow: 0px 0px 10px #e8e8eb;
  box-shadow: 0px 0px 10px #e8e8eb;
  border-radius: 15px;

  .admin-button {
    width: 600px;
    margin: 0 auto;

    .admin-button-box {
      a {
        width: 450px;
        height: 50px;
        line-height: 50px;
        background: linear-gradient(200deg, #fc4353, #ff5e41);
        border-radius: 10px;
        font-size: 20px;
        text-align: center;
        margin: 0 auto 0 124px;
        display: block;
        color: #fff;
      }

      span {
        font-size: 18px;
        color: #497adc;
        text-align: right;
        display: block;
        margin-top: 22px;
      }
    }
  }

  .admin-box {
    & > p {
      font-size: 24px;
      margin-bottom: 100px;
      text-align: center;
      color: #999999;
    }

    input {
      padding-left: 15px;
      box-sizing: border-box;
    }

    .admin-box-login {
      position: relative;
      display: flex;
      width: 600px;
      margin: 0 auto;

      .admin-box-login-div {
        width: 460px;
        margin: 0 auto;
      }

      & > span {
        width: 101px;
        font-size: 18px;
        display: block;
        text-align: right;
        margin-top: 14px;
        color: #333;
      }

      i {
        position: absolute;
        right: 31px;
        top: 12px;
      }
    }
  }
}
</style>
